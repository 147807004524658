import ProQuestAPIService from "./ProQuestAPIService";
import CustomEventHandlerService from './CustomEventHandlerService';

import { UserDTO } from "../Models";
import { CustomEventType } from '../Common/Enums';

class UserService extends ProQuestAPIService {
    async getAll(): Promise<UserDTO[]> {
        const response = await this.GetAsync<UserDTO[]>('user/getall');

        let users: UserDTO[] = [];

        if (response.isSuccess) {
            users = response.data;
        }

        return users;
    }

    async getById(user: UserDTO): Promise<UserDTO | null> {
        const response = await this.GetAsync<UserDTO>(`user/${user.id}`);

        if (response.isSuccess) {
            return response.data;
        }

        return null;
    }

    async add(user: UserDTO): Promise<UserDTO | null> {
        const response = await this.PostAsync<UserDTO>('user/create', user);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
            return response.data;
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
            return null;
        }
    }

    async edit(user: UserDTO): Promise<UserDTO | null> {
        const response = await this.PutAsync<UserDTO>(`user/update/${user.userid}`, user);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
            return response.data;
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
            return null;
        }
    }

    async delete(user: UserDTO): Promise<boolean> {
        const response = await this.DeleteAsync<null>(`user/delete/${user.userid}`);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, 'The user has been deleted successfully.');
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
        }

        return response.isSuccess;
    }
}

const userService = new UserService();
export default userService;