import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

import {
    SpecialtyMock,
    CredentialMock,
    FacultyPPQDataMock,
    QuestionnaireMock,
    LillyRateMock,
    BIRateMock,
    UserMock,
    HCPTypeMock,
    DashboardMock,
} from '../Mock';
import config from '../config.json';

class MockMiddleware {
    registerMocks() {
        if (config.MockConfig.Enable) {
            const _mockAdapter = new MockAdapter(axios);

            _mockAdapter.onGet(`${config.MockConfig.BaseURL}specialty/getall`).reply(200, SpecialtyMock);
            _mockAdapter.onGet(`${config.MockConfig.BaseURL}credential/getall`).reply(200, CredentialMock);
            _mockAdapter.onGet(`${config.MockConfig.BaseURL}lillyrate/getall`).reply(200, LillyRateMock);
            _mockAdapter.onGet(`${config.MockConfig.BaseURL}birate/getall`).reply(200, BIRateMock);
            _mockAdapter.onGet(`${config.MockConfig.BaseURL}facultyppqdata`).reply(200, FacultyPPQDataMock);
            _mockAdapter.onGet(`${config.MockConfig.BaseURL}facultyppqdata/1234`).reply((config) => {
                const facultyPPQData = FacultyPPQDataMock.data.find(p => p.facultyid === 1234);
                return [200, { message: '', data: facultyPPQData }];
            });
            _mockAdapter.onGet(`${config.MockConfig.BaseURL}user/getall`).reply(200, UserMock);
            _mockAdapter.onGet(`${config.MockConfig.BaseURL}questionnaire`).reply(200, QuestionnaireMock);
            _mockAdapter.onGet(`${config.MockConfig.BaseURL}hcptype/getall`).reply(200, HCPTypeMock);
            _mockAdapter.onGet(`${config.MockConfig.BaseURL}dashboard/getalldashboarddata`).reply(200, DashboardMock);
        }
    }
}

const mockMiddleware = new MockMiddleware();
export default mockMiddleware;