import { createClient, ContentfulClientApi } from "contentful";

import Utils from "../Common/Utils";
import config from "../config.json";
import {
  ContentfulAnswer, ContentfulQuestion, BrandModel,
  QuestionSkeleton, AnswerSkeleton, BrandSkeleton
} from "../Models";

const env = Utils.getEnvVars();

class ContentfulService {
  private readonly _contentfulClient: ContentfulClientApi<undefined>;

  constructor() {
    this._contentfulClient = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACEID ?? '',
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESSTOKEN ?? '',
      environment: env.Contentful.ENVIRONMENT,
      host: process.env.REACT_APP_CONTENTFUL_HOST ?? 'https://cdn.contentful.com',
    });
  }

  async getQuestions(): Promise<ContentfulQuestion[]> {
    const response = await this._contentfulClient.getEntries<QuestionSkeleton>({
      content_type: 'question',
      limit: config.Contentful.defaultLimit,
    });

    const fields = response.items.map(p => p.fields);
    return fields as ContentfulQuestion[];
  }

  async getAnswers(): Promise<ContentfulAnswer[]> {
    const response = await this._contentfulClient.getEntries<AnswerSkeleton>({
      content_type: 'answer',
      limit: config.Contentful.defaultLimit,
    });

    const answers: ContentfulAnswer[] = [];
    const responseAnswers = response.items.map((p) => p.fields);

    responseAnswers.forEach((responseAnswer) => {
      const answer: ContentfulAnswer = {
        id: responseAnswer.id,
        qid: 0,
        answerText: responseAnswer.answerText,
        answerOptions: responseAnswer.answerOptions,
        helpText: responseAnswer.helpText,
        point: responseAnswer.point,
        condition: responseAnswer.condition,
        isActive: responseAnswer.isActive,
      };

      const question = responseAnswer.qid as any;
      answer.qid = question.fields.id;

      answers.push(answer);
    });

    return answers;
  }
  async getBrands(): Promise<BrandModel[]> {
    const response = await this._contentfulClient.getEntries<BrandSkeleton>({
      content_type: 'exceptionBusinessBrand',
      limit: config.Contentful.defaultLimit,
    });

    const brands = response.items.map((p) => p.fields);
    return brands;
  }
}

const contentfulService = new ContentfulService();
export default contentfulService;
