import MockMiddleware from './MockMiddleware';
import AxiosMiddleware from './AxiosMiddleware';

class Middleware {
    apply() {
        // Register API mocking. Enable from config
        MockMiddleware.registerMocks();
        // Register axios interceptors
        AxiosMiddleware.registerInterceptors();
    }
}

const middleware = new Middleware();
export default middleware;