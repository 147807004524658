import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';

import App from './App';
import './Assets/App.css';
import Constants from './Common/Constants';

import Middlewares from './Middlewares';
Middlewares.apply();

const theme = createTheme({
  typography: {
    fontFamily: [
      Constants.DefaultFontFamily,
    ].join(','),
  }
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
);