import ProQuestAPIService from "./ProQuestAPIService";
import ChartModel from "../Components/Chart/ChartModel";
import {
    DashboardChartModel,
    DashboardDTO,
    DashboardModel,
    DashboardTableModel,
    GroupTierDashboardDTO,
    PPQByHCPDashboardDTO
} from "../Models";
import CustomEventHandlerService from "./CustomEventHandlerService";
import { CustomEventType } from "../Common/Enums";

class DashboardService extends ProQuestAPIService {
    async getDashboardData(): Promise<DashboardModel> {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        const response = await this.GetAsync<DashboardDTO>('dashboard/getalldashboarddata');

        const data: DashboardModel = {
            charts: [],
            groupTierHCPData: [],
        };

        if (response.isSuccess) {
            const {
                groupTierWiseData, ppqByHCPType,
                ppqNotTakenByHCPCount, ppqTakenByHCPCount,
                rateExceptionCount, payerExceptionCount, payerAndRateExceptionCount, totalHCPCount,
                savedQuestsCount, submittedQuestsCount, exceptionQuestsCount } = response.data;

            const ppqByHCPTypeChart = this.getPPQByHCPData(ppqByHCPType);
            const rateOverrideExceptionChart = this.getRateOverrideExceptionData(rateExceptionCount, payerExceptionCount, payerAndRateExceptionCount, totalHCPCount);
            const ppqTakenNotTakenChart = this.getPPQTakenNotTakenByHCPData(ppqTakenByHCPCount, ppqNotTakenByHCPCount);
            const ppqSubmittedSavedChart = this.getSubmittedSavedData(savedQuestsCount, submittedQuestsCount, exceptionQuestsCount);
            const groupTierHCPData = this.getGroupTierHCPData(groupTierWiseData);

            data.charts = [ppqByHCPTypeChart, rateOverrideExceptionChart, ppqTakenNotTakenChart, ppqSubmittedSavedChart];
            data.groupTierHCPData = groupTierHCPData;
        }

        CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
        return data;
    }

    private getPPQByHCPData(ppqByHCPTypes: PPQByHCPDashboardDTO[]): DashboardChartModel {
        const PPQByHCPChartData: ChartModel[] = [];

        ppqByHCPTypes.forEach((ppqByHCPType) => {
            const chartModel = new ChartModel();
            chartModel.name = ppqByHCPType.hcptype;
            chartModel.value = ppqByHCPType.count;

            switch (ppqByHCPType.hcptype) {
                case 'Pharmacist':
                    chartModel.color = '#95D0EC';
                    break;
                case 'Advanced Practice Provider':
                    chartModel.color = '#9782D1';
                    break;
                case 'Physician/Physician Researcher':
                    chartModel.color = '#D1C4F5';
                    break;
                case 'PhD/Doctoral degree holder':
                    chartModel.color = '#4E8FB9';
                    break;
                case 'Other Advanced Degree':
                    chartModel.color = '#D9CF93';
                    break;
                case 'Nurse or Allied Health Provider':
                    chartModel.color = '#BD56A5';
                    break;
            }

            PPQByHCPChartData.push(chartModel);
        });

        const PPQByHCPChartModel = {} as DashboardChartModel;
        PPQByHCPChartModel.id = 'CurrentPPQbyHCPType';
        PPQByHCPChartModel.title = 'Current PPQ by HCP Type';
        PPQByHCPChartModel.data = PPQByHCPChartData;

        return PPQByHCPChartModel;
    }

    private getRateOverrideExceptionData(
        rateExceptionCount: number, payerExceptionCount: number, payerAndRateExceptionCount: number, totalHCPCount: number): DashboardChartModel {
        const rateOverridExceptionChartData: ChartModel[] = [];

        const chartModelRateOverride = new ChartModel();
        chartModelRateOverride.name = 'Rate Override Exception';
        chartModelRateOverride.value = rateExceptionCount;
        chartModelRateOverride.color = '#E3B596';
        rateOverridExceptionChartData.push(chartModelRateOverride);

        const chartModelPayerException = new ChartModel();
        chartModelPayerException.name = 'Payer Exception';
        chartModelPayerException.value = payerExceptionCount;
        chartModelPayerException.color = '#A34B10';
        rateOverridExceptionChartData.push(chartModelPayerException);

        const chartModelPayerAndRateException = new ChartModel();
        chartModelPayerAndRateException.name = 'Payer And Rate Exception';
        chartModelPayerAndRateException.value = payerAndRateExceptionCount;
        chartModelPayerAndRateException.color = '#C26F38';
        rateOverridExceptionChartData.push(chartModelPayerAndRateException);

        const chartModelTotalHCP = new ChartModel();
        chartModelTotalHCP.name = 'Total HCP';
        chartModelTotalHCP.value = totalHCPCount;
        chartModelTotalHCP.color = '#D68F60';
        rateOverridExceptionChartData.push(chartModelTotalHCP);

        const rateOverridExceptionChartModel = {} as DashboardChartModel;
        rateOverridExceptionChartModel.id = 'RateOverrideException';
        rateOverridExceptionChartModel.title = 'Rate Override Exception';
        rateOverridExceptionChartModel.data = rateOverridExceptionChartData;

        return rateOverridExceptionChartModel;
    }

    private getPPQTakenNotTakenByHCPData(ppqTakenByHCPCount: number, ppqNotTakenByHCPCount: number): DashboardChartModel {
        const PPQTakenNotTakenByHCPChartData: ChartModel[] = [];

        const chartModelPPQTakenByHCP = new ChartModel();
        chartModelPPQTakenByHCP.name = 'PPQ taken by HCP’S';
        chartModelPPQTakenByHCP.value = ppqTakenByHCPCount;
        chartModelPPQTakenByHCP.color = '#93CCBC';
        PPQTakenNotTakenByHCPChartData.push(chartModelPPQTakenByHCP);

        const chartModelTotalHCP = new ChartModel();
        chartModelTotalHCP.name = 'PPQ not taken by HCP’s';
        chartModelTotalHCP.value = ppqNotTakenByHCPCount;
        chartModelTotalHCP.color = '#D9ECE7';
        PPQTakenNotTakenByHCPChartData.push(chartModelTotalHCP);

        const rateOverridExceptionChartModel = {} as DashboardChartModel;
        rateOverridExceptionChartModel.id = 'PPQTakenNotTakenByHCP';
        rateOverridExceptionChartModel.title = 'PPQ taken / not taken by HCP’s';
        rateOverridExceptionChartModel.data = PPQTakenNotTakenByHCPChartData;

        return rateOverridExceptionChartModel;
    }

    private getSubmittedSavedData(savedQuestsCount: number, submittedQuestsCount: number, exceptionQuestsCount: number): DashboardChartModel {
        const submittedSavedChartData: ChartModel[] = [];

        const chartModelSubmitted = new ChartModel();
        chartModelSubmitted.name = 'Submitted';
        chartModelSubmitted.value = submittedQuestsCount;
        chartModelSubmitted.color = '#547A98';
        submittedSavedChartData.push(chartModelSubmitted);

        const chartModelSaved = new ChartModel();
        chartModelSaved.name = 'Saved';
        chartModelSaved.value = savedQuestsCount;
        chartModelSaved.color = '#93B3CC';
        submittedSavedChartData.push(chartModelSaved);

        const chartModelException = new ChartModel();
        chartModelException.name = 'Exception';
        chartModelException.value = exceptionQuestsCount;
        chartModelException.color = '#7C92A3';
        submittedSavedChartData.push(chartModelException);

        const submittedSavedChartModel = {} as DashboardChartModel;
        submittedSavedChartModel.id = 'SubmittedSaved';
        submittedSavedChartModel.title = 'Submitted / Saved / Exception';
        submittedSavedChartModel.data = submittedSavedChartData;

        return submittedSavedChartModel;
    }

    private getGroupTierHCPData(groupTierWiseData: GroupTierDashboardDTO[]): DashboardTableModel[] {
        const groupTierData: DashboardTableModel[] = [];

        const groups = groupTierWiseData.sort((a, b) => a.lillygroup.localeCompare(b.lillygroup));

        groups.forEach((group) => {
            const isGroupExists = groupTierData.some(p => p.lillyGroup === group.lillygroup);

            if (!isGroupExists) {
                let groupTierModel: DashboardTableModel = {
                    lillyGroup: group.lillygroup,
                    tier1: 0,
                    tier2: 0,
                    tier3: 0,
                    tier4: 0,
                    tier5: 0,
                };

                const groupsTiers = groups.filter(p => p.lillygroup === group.lillygroup);

                groupsTiers.forEach((groupTier) => {
                    const obj: any = Object.assign({}, groupTierModel);
                    obj[`tier${groupTier.lillytier}`] = groupTier.count;
                    groupTierModel = obj;
                });

                groupTierData.push(groupTierModel);
            }
        });

        return groupTierData;
    }
}

const dashboardService = new DashboardService();
export default dashboardService;