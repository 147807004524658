import SelectModel from '../Components/Select/SelectModel';

class TierService {
    private readonly Tiers = [{ id: '1', tier: '1' }, { id: '2', tier: '2' }, { id: '3', tier: '3' }, { id: '4', tier: '4' }, { id: '5', tier: '5' }];

    async getAllForSelect(): Promise<SelectModel[]> {
        const tiers = this.Tiers.map((tier) => {
            const selectModel = new SelectModel();
            selectModel.text = tier.tier;
            selectModel.value = tier.tier;
            return selectModel;
        });

        return Promise.resolve(tiers);
    }
}

const tierService = new TierService();
export default tierService;