import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

class ExcelService {
    readonly config = {
        fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        fileExtension: '.xlsx',
    };

    createFromArray(fileName: string, data: any) {
        const { fileType, fileExtension } = this.config;

        const workSheet = XLSX.utils.json_to_sheet(data);
        const workBook = { Sheets: { 'data': workSheet }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });

        const blobData = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(blobData, fileName + fileExtension);
    }
}

const excelService = new ExcelService();
export default excelService;