import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export class RESTClient {
    private readonly _config: AxiosRequestConfig;

    constructor(baseURL: string) {
        this._config = {
            baseURL: baseURL,
        };
    }

    async get<T>(path: string): Promise<RESTResponse<T>> {
        let result = new RESTResponse<T>();

        try {
            const response = await axios.get(path, this._config);
            result = this.extractObj(response);
        } catch (err) {
            result = this.extractError(err);
        }

        return result;
    }

    async post<T>(path: string, data: any, headers?: any): Promise<RESTResponse<T>> {
        let result = new RESTResponse<T>();

        try {
            if (headers) {
                this._config.headers = headers;
            }

            const response = await axios.post(path, data, this._config);
            result = this.extractObj(response);
        } catch (err) {
            result = this.extractError(err);
        }

        return result;
    }

    async put<T>(path: string, data: any): Promise<RESTResponse<T>> {
        let result = new RESTResponse<T>();

        try {
            const response = await axios.put(path, data, this._config);
            result = this.extractObj(response);
        } catch (err) {
            result = this.extractError(err);
        }

        return result;
    }

    async delete<T>(path: string): Promise<RESTResponse<T>> {
        let result = new RESTResponse<T>();

        try {
            const response = await axios.delete(path, this._config);
            result = this.extractObj(response);
        } catch (err) {
            result = this.extractError(err);
        }

        return result;
    }

    private extractObj<T>(response: AxiosResponse<any>): RESTResponse<T> {
        const result = new RESTResponse<T>();

        result.data = response.data.data;
        result.message = response.data.message;
        result.isSuccess = true;
        result.statusCode = response.status;

        return result;
    }

    private extractError<T>(err: any): RESTResponse<T> {
        const result = new RESTResponse<T>();
        const error = err as AxiosError<any, any>;

        result.message = error.message;

        if (error.response) {
            result.isSuccess = false;
            result.statusCode = error.response.status;

            if (error.response.data) {
                result.message = error.response.data.message;
                const errors: Error[] = [];

                if (error.response.data.data) {
                    result.data = error.response.data.data;

                    if (error.response.data.data.errors) {
                        for (let [key, value] of Object.entries(error.response.data.data.errors)) {
                            const values = value as string[];
                            errors.push({ key, values });
                        }
                    }
                } else {
                    if (result.statusCode === 403 || result.statusCode === 500) {
                        try {
                            const jsonString = JSON.stringify(error.response.data);
                            const parseJson = JSON.parse(jsonString);
                            result.data = parseJson;
                        } catch (error) {
                            console.log('error', error);
                        }
                    } else {
                        result.data = error.response.data;
                    }
                }

                result.errors = errors;
            }
        }

        return result;
    }
}

export interface Error {
    key: string;
    values: string[];
}

export class RESTResponse<T> {
    isSuccess!: boolean;
    statusCode!: number;
    data!: T;
    message!: string;
    errors!: Error[];
}