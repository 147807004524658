import ProQuestAPIService from "./ProQuestAPIService";
import { FacultyDTO } from "../Models";

class FacultyService extends ProQuestAPIService {
    async getAll(): Promise<FacultyDTO[]> {
        const response = await this.GetAsync<FacultyDTO[]>(`faculty/getall`);

        if (response.isSuccess) {
            return response.data;
        }

        return [];
    }

    async getByFacultyId(facultyid: number): Promise<FacultyDTO | null> {
        const response = await this.GetAsync<FacultyDTO>(`faculty/getbyid/${facultyid}`);

        if (response.isSuccess) {
            return response.data;
        }

        return null;
    }

    async getAllInitiatePPQ(): Promise<FacultyDTO[]> {
        const response = await this.GetAsync<FacultyDTO[]>(`faculty/getall/initiateppq`);

        if (response.isSuccess) {
            return response.data;
        }

        return [];
    }
}

const facultyService = new FacultyService();
export default facultyService;