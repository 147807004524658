import ProQuestHCPAPIService from "./ProQuestHCPAPIService";
import { PPQBrowserDetailsDTO } from "../Models";

class PPQBrowserDetailsService extends ProQuestHCPAPIService {
    async add(details: PPQBrowserDetailsDTO): Promise<PPQBrowserDetailsDTO | null> {
        const response = await this.PostAsync<PPQBrowserDetailsDTO>('ppqbrowserdetails/create', details);

        if (response.isSuccess) {
            return response.data;
        }
        else {
            return null;
        }
    }

    async delete(facultyid: number): Promise<boolean> {
        const response = await this.DeleteAsync<null>(`ppqbrowserdetails/deletebyfacultyid/${facultyid}`);
        return response.isSuccess;
    }
}

const ppqBrowserDetailsService = new PPQBrowserDetailsService();
export default ppqBrowserDetailsService;