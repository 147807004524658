import React from "react";
import { Card, CardContent, Divider, Grid, Typography } from "@material-ui/core";

interface Props {
    title: string;
    titleElement?: JSX.Element | null;
    children?: React.ReactNode;
}
interface State { }
class DetailCard extends React.Component<Props, State> {
    render() {
        const { children, title, titleElement } = this.props;

        return (
            <Card>
                <CardContent>
                    <Grid container direction="row" alignItems="center" justify="space-between">
                        <Grid item>
                            <Typography style={{ fontSize: 20, fontWeight: 500, color: '#5D5D5D', marginLeft: 20 }}>
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item data-html2canvas-ignore>
                            {titleElement}
                        </Grid>
                    </Grid>
                    <Divider variant="fullWidth" style={{ marginTop: 15, marginBottom: 30 }} />
                    <div style={{ margin: 20 }}>
                        {children}
                    </div>
                </CardContent>
            </Card>
        );
    }
}

export default DetailCard;
