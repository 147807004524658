import ProQuestHCPAPIService from "./ProQuestHCPAPIService";
import CustomEventHandlerService from './CustomEventHandlerService';
import ContentfulService from "./ContentfulService";

import { QuestionnaireDTO, FacultyQuestDTO, MessageModel, ContentfulAnswer } from "../Models";
import { EventType, FieldType, SortType, CustomEventType, UserRole } from "../Common/Enums";
import HCPFileService from "./HCPFileService";
import QuestionnaireUtils from "../Common/QuestionnaireUtils";

class HCPQuestionnaireService extends ProQuestHCPAPIService {
    async getAll(facultyId: number): Promise<QuestionnaireDTO | undefined> {
        const questionnaire: QuestionnaireDTO = {
            facultyId,
            questionnaire: [],
            facultyQuests: [],
        };

        const contentfulQuestions = await ContentfulService.getQuestions();
        const contentfulAnswers = await ContentfulService.getAnswers();
        const response = await this.GetAsync<FacultyQuestDTO[]>(`hcpquestionnaire/getsavedanswers`);

        if (response.isSuccess) {
            questionnaire.facultyQuests = response.data;
        }

        contentfulQuestions.forEach((question) => {
            const questionAnswers = contentfulAnswers.filter(answer => answer.qid === question.id).sort((a, b) =>
                a.id - b.id
            );

            let activeQuestionAnswers: ContentfulAnswer[] = [];
            questionAnswers.forEach((qa) => {
                if (qa.isActive) {
                    activeQuestionAnswers.push(qa);
                } else {
                    const facultyQuest = questionnaire.facultyQuests.find(p => p.answerid === qa.id);

                    if (facultyQuest) {
                        activeQuestionAnswers.push(qa);
                    }
                }
            });

            switch (question.answerSort) {
                case SortType.ALPHA:
                    activeQuestionAnswers = activeQuestionAnswers.sort((a, b) => {
                        return a.answerOptions.localeCompare(b.answerOptions);
                    });
                    break;
                default:
                    break;
            }

            question.questionAnswers = activeQuestionAnswers;
        });

        const questions = contentfulQuestions.filter(q => {
            if (q.config) {
                if (q.config.showToRole) {
                    if (q.config.showToRole === UserRole[UserRole.LILLY_HCP]) {
                        return true;
                    }

                    return false;
                }
            }

            return true;
        });

        questionnaire.questionnaire = questions.sort((a, b) => {
            if (a.number === b.number) {
                return a.numberText.localeCompare(b.numberText);
            }

            return a.number - b.number;
        });



        return questionnaire;
    }

    async handleSaveAnswer(questionnaire: QuestionnaireDTO, answer: FacultyQuestDTO, eventType: EventType): Promise<QuestionnaireDTO> {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        let isDeleteSuccess: boolean | null = null;
        answer.facultyid = questionnaire.facultyId;

        const question = questionnaire.questionnaire.find(p => p.id === answer.questionno);
        let existsAnswer: FacultyQuestDTO | undefined = undefined;

        if (eventType === EventType.ADD) {
            switch (question?.responseType) {
                case FieldType[FieldType.MultiDropdown]:
                case FieldType[FieldType.MultiCheckbox]:
                    existsAnswer = questionnaire.facultyQuests.find(p =>
                        p.questionno === answer.questionno && p.answer === answer.answer);

                    break;
                default:
                    existsAnswer = questionnaire.facultyQuests.find(p =>
                        p.questionno === answer.questionno);

                    break;
            }
        }
        else if (eventType === EventType.REMOVE) {
            existsAnswer = questionnaire.facultyQuests.find(p =>
                p.questionno === answer.questionno && p.answer === answer.answer);
        }

        if (existsAnswer) {
            isDeleteSuccess = await this.delete(existsAnswer);

            if (isDeleteSuccess) {
                questionnaire.facultyQuests.splice(questionnaire.facultyQuests.indexOf(existsAnswer), 1);
            }
        }

        if (eventType === EventType.ADD) {
            if (isDeleteSuccess === null || isDeleteSuccess === true) {
                const createdAnswer = await this.create(answer);

                if (createdAnswer) {
                    answer = createdAnswer;
                    questionnaire.facultyQuests.push(answer);
                }
            }
        }

        if (isDeleteSuccess === null || isDeleteSuccess === true) {
            const logicalAnswers = QuestionnaireUtils.getLogicalAnswers(questionnaire, answer);

            if (logicalAnswers.length > 0) {
                await Promise.all(logicalAnswers.map(async (logicalAnswer) => {
                    const existsAnswer = questionnaire.facultyQuests.find(p =>
                        p.questionno === logicalAnswer.facultyQuestDTO.questionno && p.answer.includes(logicalAnswer.facultyQuestDTO.answer));

                    // if (existsAnswer) {
                    //     await this.delete(existsAnswer);
                    //     questionnaire.facultyQuests.splice(questionnaire.facultyQuests.indexOf(existsAnswer), 1);
                    // }

                    if (!existsAnswer && logicalAnswer.eventType === EventType.ADD) {
                        const createdAnswer = await this.create(logicalAnswer.facultyQuestDTO);

                        if (createdAnswer) {
                            questionnaire.facultyQuests.push(createdAnswer);
                        }
                    }
                }));
            }

            await this.deleteNextQuestionAnswers(questionnaire, answer, eventType);
        }

        CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
        return questionnaire;
    }

    async handleSubmitAnswer(questionnaire: QuestionnaireDTO): Promise<boolean> {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        const isPPQPDFUploadSuccess = await HCPFileService.uploadPPQPDF(questionnaire.facultyId);

        if (isPPQPDFUploadSuccess) {
            const isSuccess = await this.submit(questionnaire.facultyQuests);

            CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
            return isSuccess;
        } else {
            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, 'Failed to upload PPQ PDF.');

            CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
            return false;
        }
    }

    private async create(facultyQuest: FacultyQuestDTO): Promise<FacultyQuestDTO | null> {
        const response = await this.PostAsync<FacultyQuestDTO | MessageModel>(`hcpquestionnaire/create`, facultyQuest);

        if (response.isSuccess) {
            return response.data as FacultyQuestDTO;
        }
        else {
            let message = response.message;

            if (response.statusCode === 401) {
                const jwtError = response.data as MessageModel;
                message = jwtError.message;
            }

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
            return null;
        }
    }

    private async delete(facultyQuest: FacultyQuestDTO): Promise<boolean> {
        const response = await this.DeleteAsync<null>(`hcpquestionnaire/delete/${facultyQuest.id}`);
        return response.isSuccess;
    }

    private async submit(facultyQuests: FacultyQuestDTO[]): Promise<boolean> {
        const response = await this.PostAsync<boolean>(`hcpquestionnaire/submit`, facultyQuests);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
        }
        else {
            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, response.message);
        }

        return response.isSuccess;
    }

    private deleteNextQuestionAnswers = async (questionnaire: QuestionnaireDTO, answer: FacultyQuestDTO, eventType: EventType) => {
        const answers = QuestionnaireUtils.getAnswersToDelete(questionnaire, answer, eventType);

        await Promise.all(answers.map(async (existsAnswer) => {
            await this.delete(existsAnswer);
            questionnaire.facultyQuests.splice(questionnaire.facultyQuests.indexOf(existsAnswer), 1);
        }));
    }
}

const hcpPQuestionnaireService = new HCPQuestionnaireService();
export default hcpPQuestionnaireService;