import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";

import { FMVDashboardModel, FMVDashboardTableModel } from "../../../Models";
import DashboardChartModel from "./../../../Models/DashboardChartModel";
import PageHeader from "../../../Components/Text/PageHeader";
import Doughnut from "../../../Components/Chart/Doughnut";
import DashboardCard from "./DashboardCard";
import DashboardTable from "./DashboardTable";
import Data from "./DashboardHCPTypeData";
// import DashboardBusinessBrandData from "./DashboardBusinessBrandData";
import GroupBarChart from "../../../Components/Chart/BarChart/GroupBarChart";
import SimpleBarChart from "../../../Components/Chart/BarChart/SimpleBarChart";
//import DashboardBusinessUnitData from "./DashboardBusinessUnitData";
// import DashboardBusinessBrandData from "./DashboardBusinessBrandData";
// import FMVRateExceptionService from "../../../Services/FMVRateExceptionService";
import SingleSelect from "../../../Components/Select/SingleSelect";
import SelectModel from "../../../Components/Select/SelectModel";
import { ValidationType } from "../../../Common/Enums";
import {
  FMVRateExceptionService,
  FacultyPPQDataService,
} from "../../../Services";
import ChartModel from "../../../Components/Chart/ChartModel";
import BarChartModel from "../../../Components/Chart/BarChartModel";
import DashboardBrandTable from "./DashboardBrandTable";

interface Props {}
interface State {
  dashboardData: FMVDashboardModel;
  donutData: DashboardChartModel[];
  ppqAndHcpTypeData: any;
  groupChartData: any;
  chartData: BarChartModel[];
  dashboardTableData: FMVDashboardTableModel[];
  brandData: ChartModel[];
  brandDataCount: any;
  brandTableData: any;
}
class Dashboard extends React.Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      dashboardData: {
        charts: [],
        groupTierHCPData: [],
      },
      donutData: [],
      ppqAndHcpTypeData: [],
      groupChartData: [],
      chartData: [],
      dashboardTableData: [],
      brandData: [],
      brandDataCount: [],
      brandTableData: [],
    };
  }

  async componentDidMount() {
    const data: any = Data;

    const exceptionData = await FMVRateExceptionService.getAll();
    const response = await FacultyPPQDataService.getTieredData();
    //console.log("response.facultyPPQData", response.facultyPPQData);
    let HCPPPQYear: any = [];
    const tableData: any = [];
    const tableDeniedData: any = [];
    if (exceptionData && response) {
      let brandChartData: any = [];
      let approvedData: any = [];
      let deniedData: any = [];
      for (let i = 0; i < exceptionData.length; i++) {
        let exceptionResult = exceptionData[i];

        // brand data count
        let brandData = response.facultyPPQData.find(
          (v) => v.facultyid === exceptionResult.facultyid
        );

        let brandIndex = brandChartData.findIndex(
          (p: any) => p.name === exceptionResult.associatedwithactivity
        );

        if (brandIndex === -1) {
          brandChartData.push({
            ppqyear: brandData?.ppqyear,
            name: exceptionResult.associatedwithactivity,
            value: 1,
          });
        } else {
          let brandValue = brandChartData.find(
            (p: any) => p.name === exceptionResult.associatedwithactivity
          );

          brandChartData[brandIndex] = {
            ppqyear: brandData?.ppqyear,
            name: exceptionResult.associatedwithactivity,
            value: brandValue.value + 1,
          };
        }

        // console.log('brandChartData', brandChartData);
        // console.log("brandChartDataSort", brandChartData.sort((a:any, b:any)=> b.value - a.value));

        // Table data count
        if (
          exceptionResult.exceptiondecision ===
          "Exception Approved By Committee Member"
        ) {
          approvedData.push(exceptionResult);
          let approved: any = response.facultyPPQData.find(
            (v) => v.facultyid === exceptionResult.facultyid
          );
          let approvedIndex = tableData.findIndex(
            (p: any) => p.ppqYear === approved?.ppqyear
          );
          if (approvedIndex === -1) {
            tableData.push({
              ppqYear: approved?.ppqyear,
              exceptionApproved: 1,
            });
          } else {
            let ppqYearApproved = tableData.find(
              (p: any) => p.ppqYear === approved?.ppqyear
            );
            tableData[approvedIndex] = {
              ppqYear: approved?.ppqyear,
              exceptionApproved: ppqYearApproved.exceptionApproved + 1,
            };
          }
          //console.log("approved", approved?.ppqyear);
        }

        if (
          exceptionResult.exceptiondecision ===
          "Exception Denied By Committee Member"
        ) {
          deniedData.push(exceptionResult);
          let denied: any = response.facultyPPQData.find(
            (v) => v.facultyid === exceptionResult.facultyid
          );
          let deniedIndex = tableDeniedData.findIndex(
            (p: any) => p.ppqYear === denied?.ppqyear
          );
          if (deniedIndex === -1) {
            tableDeniedData.push({
              ppqYear: denied?.ppqyear,
              exceptionDenied: 1,
            });
          } else {
            let ppqYearDenied = tableDeniedData.find(
              (p: any) => p.ppqYear === denied?.ppqyear
            );
            tableDeniedData[deniedIndex] = {
              ppqYear: denied?.ppqyear,
              exceptionDenied: ppqYearDenied.exceptionDenied + 1,
            };
          }
        }

        //Doughnut chart data count
        let data = response.facultyPPQData.find(
          (v) => v.facultyid === exceptionResult.facultyid
        );

        let ppqyearIndex = HCPPPQYear.findIndex(
          (p: any) => p.ppqyear === data?.ppqyear && p.name === data?.hcptype
        );
        if (ppqyearIndex === -1) {
          HCPPPQYear.push({
            ppqyear: data?.ppqyear,
            name: data?.hcptype,
            value: 1,
            unit: exceptionResult.businessunitrequestingexception,
          });
        } else {
          let ppqYear = HCPPPQYear.find(
            (p: any) => p.ppqyear === data?.ppqyear && p.name === data?.hcptype
          );
          HCPPPQYear[ppqyearIndex] = {
            ppqyear: data?.ppqyear,
            name: data?.hcptype,
            value: ppqYear.value + 1,
            unit: exceptionResult.businessunitrequestingexception,
          };
        }
      }

      //table model
      const yearData = response.facultyPPQData.map((p) => p.ppqyear);
      // console.log('yearData', yearData);
      // if(yearData){
      //   console.log('yearData', yearData.sort().reverse());

      // }

      var totalPPQS = yearData.reduce((prev: any, cur: any) => {
        prev[cur] = (prev[cur] || 0) + 1;
        return prev;
      }, {});

      // console.log('totalPPQS', totalPPQS);

      const finalArr: FMVDashboardTableModel[] = [];

      for (let i in totalPPQS) {
        const obj = tableData.find((p: any) => p.ppqYear === parseInt(i));
        const deniedObj = tableDeniedData.find(
          (q: any) => q.ppqYear === parseInt(i)
        );
        const sampleObj: FMVDashboardTableModel = {
          ppqYear: parseInt(i),
          totalPPQ: totalPPQS[i],
          exceptionApproved: 0,
          exceptionDenied: 0,
          ppqSubmittedPercent: 0,
        };
        if (obj) {
          sampleObj.exceptionApproved = obj.exceptionApproved;
        }
        if (deniedObj) {
          sampleObj.exceptionDenied = deniedObj.exceptionDenied;
        }
        sampleObj.ppqSubmittedPercent = parseFloat(
          ((sampleObj.exceptionApproved / sampleObj.totalPPQ) * 100).toFixed(2)
        );

        finalArr.push(sampleObj);
      }

      let tableDataArr: FMVDashboardTableModel[] = finalArr.sort(
        (a, b) => b.ppqYear - a.ppqYear
      );

      let sortedBrandChartData: any[] = brandChartData.sort(
        (a: any, b: any) => b.value - a.value
      );
      // console.log("finalArr", finalArr.sort((a, b)=> b.ppqYear - a.ppqYear));
      // console.log("yearData", totalPPQS);
      // console.log("deniedData", tableData, tableDeniedData);
      // console.log("HCPPPQYear", HCPPPQYear);
      this.setState({
        ppqAndHcpTypeData: HCPPPQYear,
        groupChartData: HCPPPQYear,
        dashboardTableData: tableDataArr,
        brandDataCount: brandChartData,
        brandTableData: sortedBrandChartData,
      });

      if (brandChartData) {
        const selectedBrandYear = brandChartData.filter(
          (d: any) => d.ppqyear === this.currentYear
        );

        // console.log("selectedValue", selected.value);

        const brandObjData: ChartModel[] = [];
        selectedBrandYear.forEach((brandYear: any) => {
          const chartModel = new ChartModel();
          chartModel.name = brandYear.name;
          chartModel.value = brandYear.value;
          //console.log("brandYear", brandYear);

          chartModel.color =
            "#" + Math.floor(Math.random() * 16777215).toString(16);

          brandObjData.push(chartModel);
        });
        // const chartData: DashboardChartModel[] = [];
        // const obj: DashboardChartModel = {
        //   id: "",
        //   title: "",
        //   data: brandObjData,
        // };
        // chartData.push(obj);

        // console.log("obj", obj);

        this.setState({ brandData: brandObjData });
      }

      // Bar chart data count
      let group = HCPPPQYear.reduce((r: any, a: any) => {
        r[a.unit] = [...(r[a.unit] || []), a];
        return r;
      }, {});

      //console.log("group", group);

      let keyData = Object.keys(group);
      let groupValue = keyData.map((p: any) => group[p]);
      //console.log("v", v);
      const arrData: BarChartModel[] = [];
      groupValue.forEach((unit: any) => {
        //console.log("unit", unit);
        const barchartModel: BarChartModel = {
          name: unit[0].unit,
          barChart: [],
        };

        unit.forEach((data: any) => {
          let unitIndex = barchartModel.barChart.findIndex(
            (d: any) => d.name === data.ppqyear
          );
          //console.log("unitIndex", unitIndex);
          if (unitIndex === -1) {
            const chartModel = new ChartModel();
            chartModel.name = data.ppqyear;
            chartModel.value = data.value;
            switch (data.ppqyear) {
              case this.currentYear:
                chartModel.color = "#95D0EC";
                break;
              case this.currentYear - 1:
                chartModel.color = "#9782D1";
                break;
              case this.currentYear - 2:
                chartModel.color = "#D1C4F5";
                break;
              case this.currentYear - 3:
                chartModel.color = "#4E8FB9";
                break;
              case this.currentYear - 4:
                chartModel.color = "#D9CF93";
                break;
              case this.currentYear - 5:
                chartModel.color = "#BD56A5";
                break;
              case this.currentYear - 6:
                chartModel.color = "#BD56A7";
                break;
            }
            barchartModel.barChart.push(chartModel);
            //console.log("chartModel", chartModel);
          } else {
            let findUnit = barchartModel.barChart.find(
              (d: any) => d.name === data.ppqyear
            );
            if (findUnit) {
              barchartModel.barChart[unitIndex] = {
                name: findUnit?.name,
                value: findUnit?.value + data.value,
                color: findUnit?.color,
              };
            }
            //console.log("findUnit", findUnit);
          }
          // barchartModel.barChart.push(chartModel);
        });
        //console.log("barchartModel", barchartModel);
        arrData.push(barchartModel);
      });
      //console.log("arrData", arrData);
      if (HCPPPQYear) {
        const intialData = HCPPPQYear.filter(
          (p: any) => p.ppqyear === this.currentYear
        );
        const objData: ChartModel[] = [];
        intialData.forEach((ppqByHCPType: any) => {
          const chartModel = new ChartModel();
          chartModel.name = ppqByHCPType.name;
          chartModel.value = ppqByHCPType.value;
          //console.log("ppqByHCPType", ppqByHCPType);
          switch (ppqByHCPType.name) {
            case "Pharmacist":
              chartModel.color = "#95D0EC";
              break;
            case "Advanced Practice Provider":
              chartModel.color = "#9782D1";
              break;
            case "Physician/Physician Researcher":
              chartModel.color = "#D1C4F5";
              break;
            case "PhD/Doctoral degree holder":
              chartModel.color = "#4E8FB9";
              break;
            case "Other Advanced Degree":
              chartModel.color = "#D9CF93";
              break;
            case "Nurse or Allied Health Provider":
              chartModel.color = "#BD56A5";
              break;
          }

          objData.push(chartModel);
        });
        const chartData: DashboardChartModel[] = [];
        const obj: DashboardChartModel = {
          id: "exceptionbyhcptype",
          title: "Exception by HCP Type",
          data: objData,
        };
        chartData.push(obj);

        this.setState({
          dashboardData: data,
          donutData: chartData,
          chartData: arrData,
        });
      }
    }
  }

  currentYear = new Date().getFullYear();
  values = () => {
    const arr: number[] = [];
    for (let i = 0; this.currentYear - i >= 2016; i++) {
      arr.push(this.currentYear - i);
    }
    return arr.map((val) => {
      return { text: `${val}`, value: val };
    });
  }
  value = this.values();

  render() {
    const {
      donutData,
      chartData,
      dashboardTableData,
      brandData,
      brandTableData,
    } = this.state;
    
    return (
      <Box m={2}>
        <Grid container direction="row" alignItems="flex-start" spacing={2}>
          <Grid item md={12}>
            <PageHeader label="Dashboard" />
          </Grid>
          <Grid item md={12}>
            <Grid item sm={3} style={{ marginBottom: 20 }}>
              <SingleSelect
                isRequired={true}
                id="yeardropdown"
                label="Select"
                validationType={ValidationType.REQUIRED}
                values={this.value}
                onChange={this.handleYearSelect}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={2}
            style={{ marginBottom: 10 }}
          >
            {donutData.map((data) => {
              return (
                <Grid item sm={4} key={`DC-${data.id}`}>
                  <DashboardCard title={data.title}>
                    <Doughnut id={data.id} data={data.data} />
                  </DashboardCard>
                </Grid>
              );
            })}

            <Grid item sm={8}>
              {dashboardTableData.length > 0 ? (
                <DashboardCard title="">
                  <div style={{ paddingTop: 10 }}>
                    <DashboardTable groupTierData={dashboardTableData} />
                  </div>
                </DashboardCard>
              ) : null}
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={2}>
            <Grid item sm={6}>
              <DashboardCard title="">
                <Grid container alignItems="center" justify="flex-end">
                  <Grid item style={{ marginRight: 30 }}>
                    <Typography
                      style={{
                        fontSize: 16,
                        fontWeight: 500,
                        color: "#181818",
                        textAlign: "center",
                      }}
                    >
                      Exceptions by Business Brands
                    </Typography>
                  </Grid>
                  <Grid item style={{ height: 10 }} sm={3}>
                    <SingleSelect
                      isRequired={true}
                      id="brandyeardropdown"
                      label="Select"
                      values={this.value}
                      onChange={this.handleBrandYearSelect}
                    />
                  </Grid>
                </Grid>

                <div style={{ marginLeft: 0, marginBottom: 0 }}>
                  {brandData.length > 0 ? (
                    <SimpleBarChart id="businessbrands" data={brandData} />
                  ) : (
                    <></>
                  )}
                </div>
              </DashboardCard>
            </Grid>

            <Grid item sm={6} style={{ marginBottom: 10 }}>
              <DashboardCard title={"Exceptions by Business Unit"}>
                <div style={{ marginLeft: 0, marginTop: 0 }}>
                  {chartData.length > 0 ? (
                    <GroupBarChart id="businessunit" data={chartData} />
                  ) : (
                    <></>
                  )}
                </div>
              </DashboardCard>
            </Grid>
            <Grid item md={12}>
              {brandTableData.length > 0 ? (
                <DashboardCard title="">
                  <div style={{ paddingTop: 10 }}>
                    <DashboardBrandTable groupTierData={brandTableData} />
                  </div>
                </DashboardCard>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  handleYearSelect = (
    selected: SelectModel,
    targetId?: string | undefined,
    isValid?: boolean | undefined
  ) => {
    const { ppqAndHcpTypeData } = this.state;
    if (targetId) {
      const data = ppqAndHcpTypeData.filter(
        (p: any) => p.ppqyear === selected.value
      );
      const objData: ChartModel[] = [];
      data.forEach((ppqByHCPType: any) => {
        const chartModel = new ChartModel();
        chartModel.name = ppqByHCPType.name;
        chartModel.value = ppqByHCPType.value;
        //console.log("ppqByHCPType", ppqByHCPType);
        switch (ppqByHCPType.name) {
          case "Pharmacist":
            chartModel.color = "#95D0EC";
            break;
          case "Advanced Practice Provider":
            chartModel.color = "#9782D1";
            break;
          case "Physician/Physician Researcher":
            chartModel.color = "#D1C4F5";
            break;
          case "PhD/Doctoral degree holder":
            chartModel.color = "#4E8FB9";
            break;
          case "Other Advanced Degree":
            chartModel.color = "#D9CF93";
            break;
          case "Nurse or Allied Health Provider":
            chartModel.color = "#BD56A5";
            break;
        }

        objData.push(chartModel);
      });
      const chartData: DashboardChartModel[] = [];
      const obj: DashboardChartModel = {
        id: "exceptionbyhcptype",
        title: "Exception by HCP Type",
        data: objData,
      };
      chartData.push(obj);

      //console.log("obj", obj);

      this.setState({ donutData: chartData });
    }
    // console.log('donutData', donutData);
  };

  handleBrandYearSelect = (
    selected: SelectModel,
    targetId?: string | undefined,
    isValid?: boolean | undefined
  ) => {
    if (selected.value) {
      this.setState({ brandData: [] }, () => {
        const { brandDataCount } = this.state;
        const selectedBrandYear = brandDataCount.filter(
          (d: any) => d.ppqyear === selected.value
        );

        // console.log("selectedValue", selected.value);

        const brandObjData: ChartModel[] = [];
        selectedBrandYear.forEach((brandYear: any) => {
          const chartModel = new ChartModel();
          chartModel.name = brandYear.name;
          chartModel.value = brandYear.value;
          //console.log("brandYear", brandYear);
          chartModel.color =
            "#" + Math.floor(Math.random() * 16777215).toString(16);

          brandObjData.push(chartModel);
        });
        this.setState({ brandData: brandObjData });
      });

      // const chartData: BarChartModel[] = [];
      // const obj: BarChartModel = {
      //   year: number = 0,
      //   barChart: brandObjData,
      // };
      // chartData.push(obj);

      // console.log("obj", obj);
    }
  };
}

export default Dashboard;