import ProQuestAPIService from "./ProQuestAPIService";
import CustomEventHandlerService from "./CustomEventHandlerService";

import { CommitteeMemberManagementDTO } from "../Models";
import { CustomEventType } from "../Common/Enums";

class CommitteeMemberManagementService extends ProQuestAPIService {
  async getAll(): Promise<CommitteeMemberManagementDTO[]> {
    const response = await this.GetAsync<CommitteeMemberManagementDTO[]>(
      "committeemember/getall"
    );

    let committeemembers: CommitteeMemberManagementDTO[] = [];

    if (response.isSuccess) {
      committeemembers = response.data;
    }

    return committeemembers;
  }

  async getbyid(
    facultyid: number | string
  ): Promise<CommitteeMemberManagementDTO[] | null> {
    const response = await this.GetAsync<CommitteeMemberManagementDTO[]>(
      `committeemember/getbyid/${facultyid}`
    );

    let committeemembers: CommitteeMemberManagementDTO[] = [];

    if (response.isSuccess) {
      committeemembers = response.data;
    }

    return committeemembers;
  }

  async add(
    committee: CommitteeMemberManagementDTO
  ): Promise<CommitteeMemberManagementDTO | null> {
    //console.log("response");
    const response = await this.PostAsync<CommitteeMemberManagementDTO>(
      "committeemember/create",
      committee
    );
    //console.log("response", response);
    if (response.isSuccess) {
      // CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
      CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, {
        alertId: "added",
        message: "The Committee Member has been added successfully.",
      });
      return response.data;
    } else {
      // let message = response.message;
      const response = await this.PostAsync<CommitteeMemberManagementDTO>(
        "committeemember/create",
        committee
      );

      let errorMessage = "";
      response.errors.forEach((element: any) => {
        errorMessage += ` ${element.values[0]}`;
        //console.log(element.values[0]);
      });
      //console.log(errorMessage);

      // CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
      CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, {
        alertId: "required",
        message: errorMessage,
      });
      return null;
    }
  }

  async edit(
    committee: CommitteeMemberManagementDTO,
    id: number
  ): Promise<CommitteeMemberManagementDTO | null> {
    const response = await this.PutAsync<CommitteeMemberManagementDTO>(
      `committeemember/update/${id}`,
      committee
    );

    if (response.isSuccess) {
      // CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);

      CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, {
        alertId: "updated",
        message: "The Committee Member has been updated successfully.",
      });
      return response.data;
    } else {
      // let message = response.message;
      // CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
      CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, {
        alertId: "required",
        message: "Validation failed for two or more Fields",
      });
      return null;
    }
  }

  async delete(
    committee: CommitteeMemberManagementDTO,
    id: number
  ): Promise<boolean> {
    const response = await this.DeleteAsync<null>(
      `committeemember/delete/${id}`
    );
    if (response.isSuccess) {
      CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, {
        alertId: "Deleted",
        message: "The Committee Member has been deleted successfully.",
      });
    } else {
      let message = response.message;
      CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
    }

    return response.isSuccess;
  }
}

const committeeMemberManagementService = new CommitteeMemberManagementService();
export default committeeMemberManagementService;
