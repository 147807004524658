import React from "react";
import { Card, CardHeader, CardContent, Typography, WithStyles, withStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) => ({
    root: {
        border: '1px solid #E5E5E5',
        boxShadow: '0px 2px 6px #BCBCBC29',
        opacity: 1,
    },
});

interface Props extends WithStyles<typeof styles> {
    title: string;
    children?: React.ReactNode;
}
interface State { }
class FRCard extends React.Component<Props, State> {
    render() {
        const { children, title, classes } = this.props;

        return (
            <Card className={classes.root} variant="outlined">
                <CardHeader title={
                    <Typography style={{ fontSize: 20, fontWeight: 500, color: '#FFFFFF', marginLeft: 10 }}>
                        {title}
                    </Typography>
                }
                    style={{ backgroundColor: '#004693' }}>
                </CardHeader>
                <CardContent>
                    <div style={{ margin: 20 }}>
                        {children}
                    </div>
                </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(FRCard);
